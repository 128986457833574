

































































































// @ is an alias to /src
/* eslint-disable no-underscore-dangle */

import Chat from '@/components/chat.vue';
import AWSStream from '@/components/aws-stream.vue';
import { connectToRocketChat, getChannelMessages, joinRocketChannel } from '@/api/chat';
import { IRocketChatStreamMessage, IRocketUserLoggedIn, IVueChatMessage } from '@/models/chat';
import store from '@/store';
import { ISession } from '@/models/session';
import { getSessionById, getSessionChat } from '@/api/sessions';
import Modal from '@/components/modal.vue';
import { defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';
import router from '@/router';
import { MutationPayload } from 'vuex';

export default defineComponent({
  components: {
    Chat,
    Modal,
    AWSStream,
  },
  data() {
    return {
      event: 0,
      sessionId: 0,
      socketInit: false,
      rc: connectToRocketChat(),
      chat: {
        userId: '',
        roomId: '',
        theme: 'dark',
        rooms: [] as any,
        messages: null as IVueChatMessage[] | null,
        roomsLoadedCount: 1000,
        selectedRoom: '',
      },
      session: {} as ISession,
      chatEnabled: false,
      sessionInterval: 0,
      messagesLoaded: false,
    };
  },
  setup() {
    const { translations, language, contextVisitor } = useContext();

    return {
      translations,
      contextVisitor,
      language,
    };
  },
  computed: {
    embedUrl(): string {
      let sessionUrl = this.session.external_link;

      if (!sessionUrl || sessionUrl === '') {
        sessionUrl = `https://meet.beamian.com/${this.sessionId}`;
      }
      return sessionUrl;
    },
  },
  created() {
    this.rc = connectToRocketChat();
    this.sessionId = parseInt(this.$route.params.session, 10);
    this.event = parseInt(this.$route.params.event, 10);

    window.addEventListener(
      'message',
      (event) => {
        if (event.data === 'BACK_TO_CALENDAR') {
          router.push(`/event/${this.event}/sessions`);
        }
        if (event.data === 'BACK_TO_EVENT') {
          router.push(`/event/${this.event}`);
        }
      },
      false,
    );

    this.getSession();

    this.sessionInterval = setInterval(() => {
      if (this.sessionId) {
        this.$gtag.event(`session_watched_${this.sessionId} `, {
          event_category: `event_${this.event}`,
          event_label: `visitor_${this.contextVisitor.id}`,
          value: 30,
        });
      }
    }, 30000);

    // ping server to keepAlive
    this.rc.connectToServer().subscribe(
      () => {
        this.rc.keepAlive().subscribe(); // Ping Server
      },
      (error) => {
        console.log('ping server error', error);
      },
    );

    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setRocketUserLogin') {
        const rocketAuth = store.getters.getRocketAuth as IRocketUserLoggedIn;
        if (rocketAuth.rocket_chat_auth_token) {
          this.chat.userId = rocketAuth.rocket_chat_user_id;
          this.loginAndConnect(rocketAuth.rocket_chat_auth_token);
        }
      }
      if (
        mutation.type === 'setLanguage' ||
        (mutation.type === 'setContextVisitor' &&
          mutation.payload.id &&
          this.contextVisitor.id !== 0)
      ) {
        this.getSession();
      }
    });
  },
  destroyed() {
    clearInterval(this.sessionInterval);
  },
  methods: {
    getSession() {
      getSessionById(this.sessionId).then((response) => {
        if (response.data) {
          this.session = response.data;
          // store.commit('setContextSession', this.session);
          const rocketAuth = store.getters.getRocketAuth as IRocketUserLoggedIn;
          if (rocketAuth.rocket_chat_auth_token) {
            this.chat.userId = rocketAuth.rocket_chat_user_id;
            this.loginAndConnect(rocketAuth.rocket_chat_auth_token);
          }
        }
      });
    },

    joinChannelAndStream(channel: string) {
      joinRocketChannel({ roomId: channel, joinCode: '' }).then((response) => {
        console.log(response.data);
        this.rc.sendMessage({
          msg: 'sub',
          id: `${new Date().getTime()}`,
          name: 'stream-room-messages',
          params: [channel, false],
        });
      });
    },

    getChannelMessages(channel: string) {
      getChannelMessages(channel).then((response) => {
        const { messages } = response.data;

        if (messages) {
          this.chat = {
            ...this.chat,
            messages: [
              ...messages
                .sort((prev, next) => {
                  if (prev._updatedAt < next._updatedAt) {
                    return -1;
                  }
                  return 1;
                })
                // filter messages about users joining the channel (t property unique to those cases)
                .filter((msg) => !msg.t)
                .map((msg) => {
                  const date = new Date(msg._updatedAt as string);
                  return {
                    _id: msg._id,
                    content: msg.msg,
                    sender_id: msg.u._id,
                    username: msg.u.name,
                    date: date.toLocaleDateString(this.language),
                    timestamp: date.toLocaleTimeString(this.language),
                    system: false,
                    saved: false,
                    distributed: false,
                    seen: false,
                    disable_actions: true,
                    disable_reactions: true,
                  };
                }),
            ],
          };
          this.messagesLoaded = true;
        }
      });
    },

    loginAndConnect(token: string) {
      getSessionChat(this.sessionId).then((response) => {
        if (response.data.results.length > 0) {
          this.rc.loginWithAuthToken(token).subscribe(
            (apiEvent) => {
              if (apiEvent.msg === 'result' && !this.socketInit) {
                this.initSocket();
                this.socketInit = true;
                const roomId = response.data.results[0].rocket_chat_room_id;
                this.chatEnabled = true;
                this.chat.rooms = [
                  {
                    roomId,
                    roomName: this.session.name,
                    users: [
                      {
                        _id: 1000000000,
                        username: 'John Doe',
                        avatar: 'assets/imgs/doe.png',
                        status: {
                          state: 'online',
                          lastChanged: 'today, 14:30',
                        },
                      },
                      {
                        _id: 10000000001,
                        username: 'John Snow',
                        avatar: 'assets/imgs/snow.png',
                        status: {
                          state: 'offline',
                          lastChanged: '14 July, 20:00',
                        },
                      },
                      {
                        _id: 10000000002,
                        username: 'John Snow',
                        avatar: 'assets/imgs/snow.png',
                        status: {
                          state: 'offline',
                          lastChanged: '14 July, 20:00',
                        },
                      },
                    ],
                  },
                ];
                this.chat.roomId = roomId;
                this.chat.selectedRoom = roomId;
                this.getChannelMessages(roomId);
                this.joinChannelAndStream(roomId);
              }
            },
            (error) => {
              console.log(error);
            },
          );
        }
      });
    },

    initSocket() {
      this.rc.onError((error) => console.log(error));
      this.rc.onCompletion(() => console.log('finished'));
      this.rc.onMessage((m) => {
        const msg = m as IRocketChatStreamMessage;
        if (
          msg.fields &&
          msg.fields.args &&
          msg.fields.args[0] &&
          !msg.fields.args[0].t &&
          msg.fields.args[0].rid === this.chat.selectedRoom
        ) {
          const message = msg.fields.args[0];
          const date = new Date(message._updatedAt.$date);
          console.log(this.chat.userId);
          console.log(message.u._id);
          this.chat.messages = [
            ...(this.chat.messages as any),
            ...[
              {
                _id: msg.id,
                content: message.msg,
                sender_id: message.u._id,
                username: message.u.name,
                date: date.toLocaleDateString(this.language),
                timestamp: date.toLocaleTimeString(this.language),
                system: false,
                saved: false,
                distributed: false,
                seen: false,
                disable_actions: true,
                disable_reactions: true,
              },
            ],
          ];
        }
      });
    },

    sendMessage(msg: { content: string; file: File | null; replyMessage: string; roomId: number }) {
      this.rc.sendMessage({
        msg: 'method',
        method: 'sendMessage',
        id: `${new Date().getTime()}`,
        params: [
          {
            _id: `${new Date().getTime()}`,
            rid: this.chat.roomId,
            msg: msg.content,
          },
        ],
      });
    },
  },
});
